.loaderSection {
    position: fixed;
    top: 0;
    left: 0;
    background: #00000036;
    width: 100%;
    height: 100%;
    z-index: 99999999999999999;
}
.loaderSection .spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
}