.auth-section {
    background: #1c75bc;
    min-height: 100vh;
}
.Login,
.auth-verified{
    margin: 0 auto;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}.Login .form-content,
.auth-verified .auth-content{
    text-align: left;
    padding: 80px 30px;
    border: none;width: 90%;
/*    border-radius: .5em;
*/    vertical-align: middle;
    background: #fff;
    box-shadow: 0 0 30px rgb(167 239 199 / 51%);
}
.Login h2,
.auth-verified h2{
    font-size: 32px;
    margin-bottom: 20px;
    font-weight: 700;
    text-align: center;
    color: #fff;
}
.forgot-text a,
p.auth-msg-link a{color: #004226;text-decoration: underline;}
p.auth-msg-link{text-align: center; font-size: 14px; font-weight: 500; }
p.auth-msg-text {text-align: center; font-size: 16px; font-weight: 500; }
h4.auth-msg-subscription {
    text-align: center;
    font-weight: normal;
    font-size:18px;
    margin-bottom: 20px;
}

h4.auth-msg-subscription span{
    text-align: center;
    font-weight: bold;
    font-size:22px;display: block;
    color: #004226;
}



label {
  display: flex;
  font-weight: 600;
}

.submit-btn-1{background:#1c75bc  !important;    margin: 0; opacity: 1 !important; color: #ffffff !important; font-weight: 600; border: none; box-shadow: none; text-transform: inherit; padding:10px 30px !important; }
.submit-btn:hover,
.submit-btn:focus, 
.submit-btn:active, 
.submit-btn.active,
button.submit-btn.btn.btn-secondary:not([disabled]):not(.disabled):active{background: #004226 !important; box-shadow: none !important; }
p.forgot-text {
    text-align: right;
    margin-bottom: 0;
    padding-top: 10px;font-weight: 500;
}
button.btnCancel{background:#f61706  !important;    margin: 0; opacity: 1 !important; color: #ffffff !important; font-weight: 600; border: none; box-shadow: none; text-transform: inherit; }
button.btnCancel:hover,
button.btnCancel:focus, 
button.btnCancel:active, 
button.btnCancel.active,
button.submit-btn.btn.btn-secondary:not([disabled]):not(.disabled):active{background: #f61706 !important; box-shadow: none !important; }

.auth-text{text-align: center;}
.auth-text a{color:#004226  }
.form-control {
    font-size: 13px;
    height: 40px;
    box-shadow: none;
}
.Login-title {
  font-size: 1.5em;
}

.Login-intro {
  font-size: large;
}

@keyframes Login-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


/*By Danish 2019-08-26*/
@media only screen and (min-width : 480px) {
}
@media only screen and (min-width : 768px) {
}
@media only screen and (min-width : 992px) {
}
@media only screen and (min-width : 1200px) {
}
@media only screen and (max-width : 1199px) {
}
@media only screen and (max-width : 1024px) {
}
@media only screen and (max-width : 991px) {

.login-media img{width: 100%;}
.Login{width: 100%;}

}
@media only screen and (max-width : 767px) { 
.login-media img{width: 100%;}
.Login{width: 100%;}

} 
@media only screen and (max-width : 480px) {
 

}
.logo-section {
    width: 50%;
    margin: 0 auto;
}
.logo-section img {
    width: 100%;
}